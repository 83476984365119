
var timeout = null;
var podeEditar = false;
var isAdmin = false;
var idSiteGlobal = 0;

var master = {
    init: function () {
        'use strict'

        master.initBrowserCheck();
        master.initMasterResize();

        $(window).resize(function () {
            master.initMasterResize();
        }).resize();

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            window.addEventListener('load', function () {
                new FastClick(document.body);
            }, false);
        }


        idSiteGlobal = parseInt($("#hf_idsite").val(), 10);
        
        isAdmin = $("#hf_isAdmin").val() == "true";
        if ($("#hf_podeeditar").val() == "true") {
            podeEditar = true;
        }else{
            $("input,textarea,select:not(.ddl_tipos)").attr("disabled", "disabled");
            //$(".btn_azul,.btn_remove_media,#uploader_fotos,#uploader_docs").hide();

            $(".EditorSimples,.EditorFull").each(function () {
                $(this).after("<div class='texto_notedit'>" + $(this).val() + "</div>");
            })
            $(".EditorSimples").removeClass("EditorSimples").hide();
            $(".EditorFull").removeClass("EditorFull").hide();
        } 

    },
    bindInputHandler: function () {
        $(".validator").each(function () { if ($(this).find(".erroMark").length == 0) { $(this).html("<span class='erroMark'>*</span>" + ($(this).text() != "" ? "<span class='erroDetail'>" + $(this).text() + "</span>" : "")); } })
        $(".validInt").keypress(function (e) { return inputLimiter(e, 'int'); });
        $.placeholder.shim();
    },
    initMasterResize: function (largura) {
        if (!largura) {
            largura = $(window).width();
        }
        if (largura < 700) {
            $('html').removeClass('big').removeClass('large').removeClass('normal').removeClass('small').addClass('mini');
        }
        else if (largura >= 700 && largura < 980) {
            $('html').removeClass('big').removeClass('large').removeClass('normal').removeClass('mini').addClass('small');
        }
        else if (largura >= 980 && largura < 1220) {
            $('html').removeClass('big').removeClass('large').removeClass('small').removeClass('mini').addClass('normal');
        }
        else if (largura >= 1220 && largura < 1500) {
            $('html').removeClass('normal').removeClass('big').removeClass('small').removeClass('mini').addClass('large');
        }
        else {
            $('html').removeClass('normal').removeClass('large').removeClass('small').removeClass('mini').addClass('big');
        }
    },
    initBrowserCheck: function () {
        if (/MSIE (\d+\.\d+);/.test(navigator.userAgent)) {
            var ieversion = new Number(RegExp.$1);
            if (ieversion <= 7) {
                $("#checkbrowser").stop().slideDown("slow");
                $("#masterpage").stop().animate({ opacity: '.4' }, "normal");
            }
        }
        $('#checkbrowser .btn_close_browser ').click(function () {
            $("#checkbrowser").stop().slideUp("slow");
            $("#masterpage").stop().animate({ opacity: '1' }, "normal");
        });
    }
}

var dashborad = {
    count: 60,
    counter:null,
    init: function () {

        var _this = this,
               tabelaHolderObj = $('.dashboard-contactos'),
               opcoesTabela = {
                   pageSize: 20,
                   apiUrl: '/api/contactos/getTodosContactos.ashx',
                   apiData: { tipo: '', pg: 1 },
                   callback: _this.onTableCreated
               }

        tabela.init(tabelaHolderObj, opcoesTabela);






        /* -- FILTROS DO DASHBOARD -- */
        $(".ddl_tipos").change(function () {

            var tabela = $('.dashboard-contactos').data("WATable");
            tabela.option("urlData", { pg: '1', tipo: $(this).val() })
            tabela.update();
            
        })


        if ($("#time").length > 0) {
            // oAuto reload esta desactivado para este projecto
           // _this.counter = setInterval(_this.timer, 1000);
        }

        if (views) {
            var ctx = document.getElementById("chart-area").getContext("2d");
            window.myDoughnut = new Chart(ctx).Doughnut(views, { responsive: true, animation: false });
        }
    },

    onTableCreated:function(){
    
    },



    timer: function () {
        

        dashborad.count = dashborad.count - 1;
        if (dashborad.count <= 0) {
            clearInterval(dashborad.counter);
            location.reload();
            return;
        }
        if (dashborad.count == 1) {
            document.getElementById("time").innerHTML = "Actualização em <b>" + dashborad.count + "</b> segundo";
        } else {
            document.getElementById("time").innerHTML = "Actualização em <b>" + dashborad.count + "</b> segundos";
        }

       
    }
}


var formularios = {
    edit: function(emailsExistentes, idform) {
       


        var seccao = cookies.get("seccao");
        if (seccao == "campos") {
            $("#ContentPlaceHolder1_tab_menu4").click();
            cookies.erase("seccao");
        }


        /* -- Save -- */
        $("#div_edicao").on("click", ".btn_gravar", function () {

            if (Page_ClientValidate("CreateUserWizard1")) {
                editores.encode();
                __doPostBack($(".btn_gravar_ghost").attr("name"), '');

            } else {
                erro("Preencha os campos obrigatórios");
            }
        })

        $("#div_edicao").on("click", ".btn_adicionartag_submit", function () {
            if (Page_ClientValidate("CreateUserWizard3")) {
                editores.encode();
                __doPostBack($(".btn_adicionartag_submit_ghost").attr("name"), '');
            } else { }
        })


        $(".multitag").select2({
            tags: emailsExistentes, tokenSeparators: [";", ","],
            initSelection: function (element, callback) {
                var data = [];
                $(element.val().split(";")).each(function () {
                    if (this != "") {
                        data.push({ id: this, text: this });
                    }
                });
                callback(data);
            }
        });

        $(".singletag").select2({
            tags: emailsExistentes, tokenSeparators: [";", ","], maximumSelectionSize: 1,
            initSelection: function (element, callback) {
                var data = [];
                $(element.val().split(";")).each(function () {
                    if (this != "") {
                        data.push({ id: this, text: this });
                    }
                });
                callback(data);
            }
        });



        /* -- Click para destaque e Activo -- */
        $("#itensholder").on("click", ".js-click", function () {

            var _this = $(this),
                prop = _this.data("prop"),
                id = parseInt(_this.closest(".pnl_id").data("id"), 10),
                valor = _this.hasClass("on")? 0 : 1;

            if (id && prop) {

                _this.addClass("loading");
                _this.fadeOut(200, function () {
                    formularios.defineProp(prop, id, valor, _this);
                });
            }
        });

        /* -- Click eliminar -- */
        $(".js-click-remove").on("click", function () {

            var _this = $(this),
                prop = "eliminado",
                id = parseInt($(".edit_form>input").val(), 10),
                valor = 1;

            if (id && prop) {

                _this.addClass("loading");
                _this.fadeOut(200, function () {
                    formularios.defineProp(prop, id, valor, _this);
                });
            }
        });

        /* -- Ordenação -- */
        $("#itensholder").sortable({
            distance: 15,
            forceHelperSize: true,
            containment: "parent",
            //   axis: "y",
            forcePlaceholderSize: true,
            handle: ".move-item",
            stop: function (event, ui) {

                var array = [];
                $("#itensholder .pnl_id").each(function () {
                    array.push($(this).data("id"));
                })
                formularios.defineOrdem(array.join(','));
            }
        });


        /* -- NOVO ITEM -- */
        $(".itensDisponiveis .lbl_campo").click(function () {

            var tipo = $(this).data("tipo");

            if (tipo == 7 || tipo == 8 || tipo == 9) {
                if ($(".pnl_id[data-tipo='" + tipo + "']").length) {
                    erro('Apenas pode existir um item desse tipo');
                    return false;
                }
            }


            if (tipo == 6 || tipo == 10 || tipo == 11) {
                $(".edit_form .opcoes").addClass("active");

            }


            $("#itensholder").removeClass("open");
            $(".edit_form").addClass("open");
            $(".painelshow.open").addClass("overlay");

            $(".edit_form .lbl_titulo").text("Novo item");
            $(".edit_form>input").val("");
            $(".edit_form .ddl_tipo").val(tipo);

            $(".edit_form .ddl_largura").val("w100");

            $(".edit_form input").val("");

            $(".btn_save_form").text("Inserir");



        })

        /* -- FECHA PAINEL DE EDITCAO -- */
        $(".edit_form .edit-close").click(function(){
            $("#itensholder").addClass("open");
            $(".edit_form").removeClass("open");
            $(".painelshow.open").removeClass("overlay");

            $(".edit_form input").val("");
    })


        /* -- EDITA ITEM -- */
        $("#itensholder .edit-item").click(function () {
            var xid = $(this).closest(".pnl_id").data("id");

            $("#itensholder").removeClass("open");
            $(".edit_form").addClass("open");

            $(".painelshow.open").addClass("overlay");

            $(".edit_form .lbl_titulo").text("...");
            $(".edit_form>input").val(xid);

            $(".edit_form .opcoes").removeClass("active");
            $(".edit_form .opcoes_holder").html("");




            $(".btn_save_form").text("Gravar Alterações");
            //Get info from DB


            $.ajax({
                type: "GET",
                data: {id: xid, idsite: idSiteGlobal},
                url: '/api/formularios/getCampo.ashx',
                dataType: "html",
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                },
                success: function (xml) {

                    var data = JSON.parse(xml);
                    if (data.id) {

                        console.log(data);



                        if (data.opcoes) {
                            var opcoes = JSON.parse(data.opcoes);

                            $(".edit_form .opcoes").addClass("active");
                            $(".idiomas_paineis .pnl_idioma").each(function () {
                                var esteIdioma = $(this).data("idioma"),
                                    _thisObj = $(this).find(".opcoes_holder"),
                                    encontrou = false,
                                    index = $(this).index();

                                for (var i = 0; i < opcoes.length; i++) {

                                    var item = opcoes[i];
                                    if (item.idioma == esteIdioma) {
                                        encontrou = true;
                                        for (var j = 0; j < item.campos.length; j++) {
                                            var itemCampo = item.campos[j];
                                            _thisObj.append("<div class=''><input type='text' class='input_id' value='" + itemCampo.id + "' " + (index > 0 ? " disabled='disabled' " : "") + "/>" +
                                                "<input class='input_valor'  type='text' value='" + itemCampo.valor + "'/></div>");
                                        }
                                        i = opcoes.length;
                                    }
                                }

                                if (!encontrou) {
                                    var item = opcoes[0];
                                    for (var j = 0; j < item.campos.length; j++) {
                                        var itemCampo = item.campos[j];
                                        _thisObj.append("<div class=''><input type='text' class='input_id' value='" + itemCampo.id + "' " + (index > 0 ? " disabled='disabled' " : "") + "/>" +
                                            "<input class='input_valor'  type='text' value='" + itemCampo.valor + "'/></div>");
                                    }
                                }
                            })
                        } else if (data.tipo == 6 || data.tipo == 10 || data.tipo == 11) {
                            $(".edit_form .opcoes").addClass("active");
                        } else if (data.tipo == 12 || data.tipo == 13) {
                           

                        }

                        $(".edit_form>input").val(data.id);
                        $(".edit_form .lbl_titulo").text(data.nome_pt);
                        $(".edit_form .pnl_idioma[data-idioma=pt] .tbx_titulo").val(data.nome_pt);

                        if (!data.nome_pt1) {
                            data.nome_pt1 = data.nome_pt;
                        }
                        $(".edit_form .pnl_idioma[data-idioma=pt1] .tbx_titulo").val(data.nome_pt1);

                        $(".edit_form .ddl_largura").val(data.class);
                        $(".edit_form .ddl_tipo").val(data.tipo);
                    } else {
                        erro('Ocorreu um erro');
                    }
                }
            })
        })

        /* -- MUDA A OPCAO-- */
        $(".idiomas_paineis .pnl_idioma:first-child .opcoes_holder").on("change", "input", function () {

            var linha = $(this).parent().index(),
                indexInput = $(this).index();

            $(".idiomas_paineis .pnl_idioma:gt(0)").each(function () {
                var obj = $(this).find(".opcoes_holder div:nth(" + linha + ") input:nth(" + indexInput + ")");
                if (!obj.val()) {
                    obj.val($(this).val());
                }
            });
        })


        /* -- INSERE NOVA OPCAO -- */
        $(".insert-opcao").click(function () {

            var id = $(".insert .input_id").val(),
                valor = $(".insert .input_valor").val();

            if (id && valor) {
                $(".idiomas_paineis .pnl_idioma").each(function () {
                    var esteIdioma = $(this).data("idioma"),
                        _thisObj = $(this).find(".opcoes_holder"),
                        index = $(this).index();

                    _thisObj.append("<div class=''><input type='text' class='input_id' value='" + id + "' " + (index > 0 ? " disabled='disabled' " : "") + "/>" +
                        "<input class='input_valor'  type='text' value='" + valor + "'/></div>");
                });

                $(".insert .input_id").val("");
                $(".insert .input_valor").val("");
            } else {
                erro('Preencha todos os campos');
            }
               

        })
       

        /* -- SAVE ITEM -- */
        $(".btn_save_form").click(function () {
            var xid = $(".edit_form>input").val();
            console.log(xid);

            var nomePt = $(".edit_form .pnl_idioma[data-idioma=pt] .tbx_titulo").val(),
                    nomePt1 = $(".edit_form .pnl_idioma[data-idioma=pt1] .tbx_titulo").val(),
                    cssClass = $(".edit_form .ddl_largura").val(),
                    tipo = $(".edit_form .ddl_tipo").val();


            if (nomePt.length && cssClass.length && tipo.length) {

                var formData = {
                    id: xid,
                    npt: nomePt,
                    //npt1: nomePt1,
                    classCss: cssClass,
                    tipo: tipo,
                    idsite: idSiteGlobal
                }

                if (!xid) {
                    formData.id = 0;
                    formData.idform = idform;
                }


                var array = [];
                $(".idiomas_paineis .pnl_idioma").each(function () {
                    var camposArray = [];
                    $(this).find(".opcoes_holder>div").each(function () {
                        camposArray.push({ id: $(this).find(".input_id").val(), valor: $(this).find(".input_valor").val() });
                    })
                    if (camposArray.length) {
                    array.push({
                        idioma: $(this).data("idioma"),
                        campos: camposArray
                    })
                    }
                });

                if (array.length) {
                    formData.opcoes = JSON.stringify(array);
                }


                $.ajax({
                    type: "GET",
                    data: formData,
                    url: '/api/formularios/setProp.ashx',
                    dataType: "html",
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                    },
                    success: function (xml) {
                        if (xml != "false") {
                            $.modal.close();
                            notify("alert", "Alterações guardadas com sucesso", function () {
                                cookies.create("seccao", "campos");
                                window.location.reload();

                            })
                        } else {
                            erro('Ocorreu um erro');
                        }
                    }
                })

            } else {
                erro('Deve preencher todos os campos');
            }
        })
        
        /* -- Muda idioma -- */
        $(".idiomas_edit li").click(function () {
            $(this).addClass("active").siblings().removeClass("active");
            $(".idiomas_paineis .pnl_idioma:nth(" + $(this).index() + ")").addClass("active").siblings().removeClass("active");
        })
        $(".idiomas_edit li:nth(0)").click();

        


        },
        
    defineProp: function (prop, ids, valor, obj) {
        'use strict'

        $.ajax({
            type: "GET",
            data: { a: prop, id: ids, v: valor, idsite: idSiteGlobal },
            url: '/api/formularios/setProp.ashx',
            dataType: "html",
            success: function (xml) {


                if (prop == "eliminado") {

                    $("#itensholder").addClass("open");
                    $(".edit_form").removeClass("open");
                    $(".painelshow.open").removeClass("overlay");
                    $(".edit_form input").val("");

                    cookies.create("seccao", "campos");
                    window.location.reload();

                } else {
                    if (valor == 1) {
                        obj.addClass("on").removeClass("off");
                    } else {
                        obj.removeClass("on").addClass("off");
                    }
                    obj.fadeIn();
                    obj.removeClass("loading");
                }
               

            }, error: function (XMLHttpRequest, textStatus, errorThrown) {
            }
        })
    },
    defineOrdem: function (ordem) {
        $.ajax({
            type: "GET",
            data: { o: ordem, idsite: idSiteGlobal },
            url: '/api/formularios/setProp.ashx',
            dataType: "html",
            error: function (XMLHttpRequest, textStatus, errorThrown) {
            },
            success: function (xml) {
            }
        })
    },
}


var menus = {
    listagem: {
        pageSize: 20,
        init: function (idfam) {
            'use strict'

            var _this = this,
                tabelaHolderObj = $('#tabelaHolder'),
                opcoesTabela = {
                    pageSize: artigos.listagem.pageSize,
                    apiUrl: '/api/menus/get.ashx',
                    apiData: { idfam: idfam, pg: 1, idsite: idSiteGlobal },
                    callback: _this.onTableCreated
                }

            tabela.init(tabelaHolderObj, opcoesTabela);


            /* -- Click para adicionar artigo -- */
            $('.modal-basic').click(function (e) {

                $("#modal-content-new>input").val("");
                $("#modal-content-new input").val("");

                $(".btn_adicionar_submit").val("Inserir");

                $('#modal-content-new').modal({
                    persist: true,
                    onClose: function () {
                        $(".validation_holder").hide();
                        $(".error_popup").css("visibility", "hidden");
                        $.modal.close();
                    }
                });
                return false;
            });

           


            $(".btn_adicionar_submit").click(function () {
                var xid = $("#modal-content-new>input").val();
                console.log(xid);

                var nomePt = $("#modal-content-new .nome_pt").val(),
                    nomePt1 = $("#modal-content-new .nome_pt1").val(),
                    url = $("#modal-content-new .url").val();

                console.log(nomePt, nomePt1,url)
                if (nomePt.length && nomePt1.length && url.length) {


                    var formData = {
                        id: xid,
                        npt: nomePt,
                        npt1: nomePt1,
                        url: url,
                        idsite: idSiteGlobal
                    }

                    if (!xid) {

                        formData = {
                            id: 0,
                            npt: nomePt,
                            npt1: nomePt1,
                            url: url,
                            idsite: idSiteGlobal,
                            idpai: idfam
                        }
                    }



                    $.ajax({
                        type: "GET",
                        data: formData,
                        url: '/api/menus/setProp.ashx',
                        dataType: "html",
                        error: function (XMLHttpRequest, textStatus, errorThrown) {
                        },
                        success: function (xml) {
                            if (xml != "false") {
                                $.modal.close();
                                notify("alert", "Alterações guardadas com sucesso", function () {
                                    window.location.reload();
                                })
                            } else {
                                erro('Ocorreu um erro');
                            }
                        }
                    })

                } else {
                    erro('Deve preencher todos os campos');
                }
               
                


            })

          
        },
        onTableCreated: function () {

            $('#tabelaHolder .ordem').removeClass("ordem");

            $('#tabelaHolder').find(".watable  tbody tr").each(function () {
                $(this).find("td:nth(5)").addClass("ordem");
            })



            $(".watable  tbody  tr").click(function (e) {

                var abreEdit = $(this).find("a").attr("href") == "#";

                if($(e.target).closest(".tbcol7").length){
                    abreEdit=true;
                }

                if ($(e.target).closest(".tbcol6").length) {
                    abreEdit = false;
                }

              


                if (abreEdit) {
                    var xid = parseInt($(this).find(".tbcol1").text(), 10);

                    $(".btn_adicionar_submit").val("Editar");
                    $("#modal-content-new>input").val(xid);

                    var data = $(this).find(".tbcol2 a").data("info").replace("[", "").replace("]", "").replace(/'/gi, "").split(',');
                    if (data.length == 2) {
                        $("#modal-content-new .nome_pt").val(data[0]);
                        $("#modal-content-new .nome_pt1").val(data[1]);
                        $("#modal-content-new .url").val($(this).find(".tbcol4").text())

                        $('#modal-content-new').modal({
                            persist: true,
                            onClose: function () {
                                $(".validation_holder").hide();
                                $(".error_popup").css("visibility", "hidden");
                                $.modal.close();
                            }
                        });
                    }
                    return false;
                }
            })


            //BOTOES DE ACÇÃO
            if (podeEditar) {

                /* -- Ordenação de artigos -- */
                $(".listagem-principal  tbody").sortable({
                    distance: 15,
                    forceHelperSize: true,
                    containment: "parent",
                    axis: "y",
                    forcePlaceholderSize: true,
                    handle: ".ordem",
                    stop: function (event, ui) {

                        var order = $(".listagem-principal tbody").sortable('serialize').replace(/&/g, "-"),
                            active = parseInt($(".pagination .active").text(), 10);

                        menus.defineOrdem(order, menus.listagem.pageSize, active);
                    }
                });

                /* -- Click para destaque e Activo -- */
                $(".listagem-principal").on("click", ".js-click", function () {

                    var _this = $(this),
                        prop = "",
                        id = parseInt(_this.closest("tr").find(".tbcol1").text(), 10),
                        valor = _this.attr("src").indexOf("off") > 0 ? 1 : 0;

                    if (_this.hasClass("js-toggleActivo")) {
                        prop = "activo";
                    }
                    else if (_this.hasClass("js-togglePublico")) {
                        prop = "publico";
                    }

                    _this.addClass("loading");
                    _this.fadeOut(200, function () {
                        menus.defineProp(prop, id, valor, _this);
                    });
                });


            }

        }
       
    },
    defineProp: function (prop, ids, valor, obj) {
        'use strict'

        $.ajax({
            type: "GET",
            data: { a: prop, id: ids, v: valor, idsite: idSiteGlobal },
            url: '/api/menus/setProp.ashx',
            dataType: "html",
            success: function (xml) {

                obj.attr("src", "/assets/release/images/" + prop + "_" + (valor == 1 ? "on" : "off") + ".png");
                obj.fadeIn();
                obj.removeClass("loading");

            }, error: function (XMLHttpRequest, textStatus, errorThrown) {
            }
        })
    },
    defineOrdem: function (ordem, pagesize, actual) {
        $(".ordem").animate({ "text-indent": -20 }, 100);
        $.ajax({
            type: "GET",
            data: { o: ordem, p: pagesize, a: actual, idsite: idSiteGlobal },
            url: '/api/menus/setProp.ashx',
            dataType: "html",
            error: function (XMLHttpRequest, textStatus, errorThrown) {
            },
            success: function (xml) {
                var startCount = pagesize * (actual - 1);
                $(".ordem").each(function () {
                    var ordem = $(this).parent().index() + 1 + startCount
                    if (ordem < 10)
                        ordem = "0" + ordem;
                    $(this).text(ordem);
                })
                $(".ordem").animate({ "text-indent": 0 }, 200);
            }
        })
    },
}



var social = {
    listagem: {
        pageSize: 20,
        init: function (idfam) {
            'use strict'

            var _this = this,
                tabelaHolderObj = $('#tabelaHolder'),
                opcoesTabela = {
                    pageSize: artigos.listagem.pageSize,
                    apiUrl: '/api/social/get.ashx',
                    apiData: { pg: 1, idsite: idSiteGlobal },
                    callback: _this.onTableCreated
                }

            tabela.init(tabelaHolderObj, opcoesTabela);


        },
        onTableCreated: function () {

            $('#tabelaHolder .ordem').removeClass("ordem");

            $('#tabelaHolder').find(".watable  tbody tr").each(function () {
                $(this).find("td:nth(5)").addClass("ordem");
            })



            $(".btn_adicionar_submit").click(function () {
                var xid = $("#modal-content-new>input").val();
                console.log(xid);

                var nomePt = $("#modal-content-new .nome_pt").val(),
                    url = $("#modal-content-new .url").val();

                console.log(nomePt, url)
                if (nomePt.length && url.length) {


                    var formData = {
                        id: xid,
                        npt: nomePt,
                        url: url,
                        idsite: idSiteGlobal
                    }

                    $.ajax({
                        type: "GET",
                        data: formData,
                        url: '/api/social/setProp.ashx',
                        dataType: "html",
                        error: function (XMLHttpRequest, textStatus, errorThrown) {
                        },
                        success: function (xml) {
                            if (xml != "false") {
                                $.modal.close();
                                notify("alert", "Alterações guardadas com sucesso", function () {
                                    window.location.reload();
                                })
                            } else {
                                erro('Ocorreu um erro');
                            }
                        }
                    })

                } else {
                    erro('Deve preencher todos os campos');
                }

            })


            $(".watable  tbody  tr").click(function (e) {

                var abreEdit = $(this).find("a").attr("href") == "#";

                if ($(e.target).closest(".tbcol7").length) {
                    abreEdit = true;
                }

                if ($(e.target).closest(".tbcol6").length) {
                    abreEdit = false;
                }

                if (abreEdit) {
                    var xid = parseInt($(this).find(".tbcol1").text(), 10);

                    $(".btn_adicionar_submit").val("Editar");
                    $("#modal-content-new>input").val(xid);

                    var data = $(this).find(".tbcol2 a").data("info").replace("[", "").replace("]", "").replace(/'/gi, "").split(',');
                    if (data.length == 2) {
                        $("#modal-content-new .nome_pt").val(data[0]);
                        $("#modal-content-new .nome_pt1").val(data[1]);
                        $("#modal-content-new .url").val($(this).find(".tbcol4").text())

                        $('#modal-content-new').modal({
                            persist: true,
                            onClose: function () {
                                $(".validation_holder").hide();
                                $(".error_popup").css("visibility", "hidden");
                                $.modal.close();
                            }
                        });
                    }
                    return false;
                }
            })


            //BOTOES DE ACÇÃO
            if (podeEditar) {

                /* -- Ordenação de artigos -- */
                $(".listagem-principal  tbody").sortable({
                    distance: 15,
                    forceHelperSize: true,
                    containment: "parent",
                    axis: "y",
                    forcePlaceholderSize: true,
                    handle: ".ordem",
                    stop: function (event, ui) {

                        var order = $(".listagem-principal tbody").sortable('serialize').replace(/&/g, "-"),
                            active = parseInt($(".pagination .active").text(), 10);

                        social.defineOrdem(order, social.listagem.pageSize, active);
                    }
                });

                /* -- Click para destaque e Activo -- */
                if (!$(".listagem-principal").hasClass("clicksBinded")) {

                    $(".listagem-principal").addClass("clicksBinded")
                    $(".listagem-principal").on("click", ".js-click", function () {

                        var _this = $(this),
                            prop = "",
                            id = parseInt(_this.closest("tr").find(".tbcol1").text(), 10),
                            valor = _this.attr("src").indexOf("off") > 0 ? 1 : 0;

                        if (_this.hasClass("js-toggleActivo")) {
                            prop = "activo";
                        }
                        else if (_this.hasClass("js-togglePublico")) {
                            prop = "publico";
                        }

                        _this.addClass("loading");
                        _this.fadeOut(200, function () {
                            social.defineProp(prop, id, valor, _this);
                        });
                    });
                }


            }

        }

    },
    defineProp: function (prop, ids, valor, obj) {
        'use strict'

        $.ajax({
            type: "GET",
            data: { a: prop, id: ids, v: valor, idsite: idSiteGlobal },
            url: '/api/social/setProp.ashx',
            dataType: "html",
            success: function (xml) {

                obj.attr("src", "/assets/release/images/" + prop + "_" + (valor == 1 ? "on" : "off") + ".png");
                obj.fadeIn();
                obj.removeClass("loading");

            }, error: function (XMLHttpRequest, textStatus, errorThrown) {
            }
        })
    },
    defineOrdem: function (ordem, pagesize, actual) {
        $(".ordem").animate({ "text-indent": -20 }, 100);
        $.ajax({
            type: "GET",
            data: { o: ordem, p: pagesize, a: actual, idsite: idSiteGlobal },
            url: '/api/social/setProp.ashx',
            dataType: "html",
            error: function (XMLHttpRequest, textStatus, errorThrown) {
            },
            success: function (xml) {
                var startCount = pagesize * (actual - 1);
                $(".ordem").each(function () {
                    var ordem = $(this).parent().index() + 1 + startCount
                    if (ordem < 10)
                        ordem = "0" + ordem;
                    $(this).text(ordem);
                })
                $(".ordem").animate({ "text-indent": 0 }, 200);
            }
        })
    }
}

var artigos = {
    listagem: {
        pageSize: 20,
        init: function (idfam) {
           
            var _this = this,
                tabelaHolderObj = $('#tabelaHolder'),
                opcoesTabela = {
                    pageSize: artigos.listagem.pageSize,
                    apiUrl: '/api/artigos/get.ashx',
                    apiData: { idfam: idfam, pg: 1, idsite: idSiteGlobal },
                    callback: _this.onTableCreated
                }

            tabela.init(tabelaHolderObj, opcoesTabela);


            /* -- Click para adicionar artigo -- */
            $('.modal-basic').click(function (e) {
                $('#modal-content-new').modal({
                    persist: true,
                    onClose: function () {
                        $(".validation_holder").hide();
                        $(".error_popup").css("visibility", "hidden");
                        $.modal.close();
                    }
                });
                return false;
            });

            /* -- Click para adicionar artigo -- */
            $('.btn_adicionar_submit').click(function (e) {
                $('.txt1').val($('.nome_pt').val());
                $('.txt2').val($('.ddl').val());
                __doPostBack($(".btn_adicionar_submit2").attr("name"), '');
            });


        },
        onTableCreated: function () {


            //BOTOES DE ACÇÃO
            if (podeEditar) {

                /* -- Ordenação de artigos -- */
                $(".listagem-principal  tbody").sortable({
                    distance: 15,
                    forceHelperSize: true,
                    containment: "parent",
                    axis: "y",
                    forcePlaceholderSize: true,
                    handle: ".ordem",
                    stop: function (event, ui) {

                        var order = $(".listagem-principal tbody").sortable('serialize').replace(/&/g, "-"),
                            active = parseInt($(".pagination .active").text(), 10);

                        artigos.defineOrdem(order, artigos.listagem.pageSize, active);
                    }
                });

                /* -- Click para destaque e Activo -- */
                $(".listagem-principal").on("click", ".js-click", function () {

                    var _this = $(this),
                        prop = "",
                        id = parseInt(_this.closest("tr").find(".tbcol1").text(), 10),
                        valor = _this.attr("src").indexOf("off") > 0 ? 1 : 0;

                    if (_this.hasClass("js-toggleActivo")) {
                        prop = "activo";
                    }
                    else if (_this.hasClass("js-togglePublico")) {
                        prop = "publico";
                    }

                    _this.addClass("loading");
                    _this.fadeOut(200, function () {
                        artigos.defineProp(prop, id, valor, _this);
                    });
                });


            }

        }
    },
    detalhe: {
        init: function (idfam) {


            if ($("#tabelaHolder").length) {
                var _this = this,
                    tabelaHolderObj = $('#tabelaHolder'),
                    opcoesTabela = {
                        pageSize: artigos.listagem.pageSize,
                        apiUrl: '/api/artigos/get.ashx',
                        apiData: { idfam: idfam, pg: 1, idsite: idSiteGlobal },
                        callback: _this.onTableCreated
                    }
                tabela.init(tabelaHolderObj, opcoesTabela);
            }


            editores.init();

            tabs.init(".module-menu-textos", function (obj) {

                if (obj.find(">span").hasClass("tab_localizacao") && !mapa.existe) {
                    setTimeout(function () {
                        mapa.load();
                    }, 300);
                } else if (obj.find(">span").hasClass("tab_multimedia") && !uploader.existe) {
                    setTimeout(function () {
                        uploader.load();
                    }, 300);
                } else if (obj.find(">span").hasClass("tab_horarios") && !horarios.existe) {
                    setTimeout(function () {
                        horarios.init();
                    }, 300);
                }
            });

            //this.bindClickMenus();

            if (podeEditar) {
                this.bindAdminClicks();

                media.loadSortable();
                media.bindClicks();
            }
        },
            onTableCreated: function () {


                //BOTOES DE ACÇÃO
                if (podeEditar) {

                    /* -- Ordenação de artigos -- */
                    $(".listagem-principal  tbody").sortable({
                        distance: 15,
                        forceHelperSize: true,
                        containment: "parent",
                        axis: "y",
                        forcePlaceholderSize: true,
                        handle: ".ordem",
                        stop: function (event, ui) {

                            var order = $(".listagem-principal tbody").sortable('serialize').replace(/&/g, "-"),
                                active = parseInt($(".pagination .active").text(), 10);

                            artigos.defineOrdem(order, artigos.listagem.pageSize, active);
                        }
                    });

                    /* -- Click para destaque e Activo -- */
                    $(".listagem-principal").on("click", ".js-click", function () {

                        var _this = $(this),
                            prop = "",
                            id = parseInt(_this.closest("tr").find(".tbcol1").text(), 10),
                            valor = _this.attr("src").indexOf("off") > 0 ? 1 : 0;

                        if (_this.hasClass("js-toggleActivo")) {
                            prop = "activo";
                        }
                        else if (_this.hasClass("js-togglePublico")) {
                            prop = "publico";
                        }

                        _this.addClass("loading");
                        _this.fadeOut(200, function () {
                            artigos.defineProp(prop, id, valor, _this);
                        });
                    });


                }

            },
        bindClickMenus: function () {


            $("#editores .divs:first-child").fadeIn();
            $("#submenuTextos .submenuitem:first-child").addClass("active");

            $("#tabs>.tab").click(function () {
                var _this = $(this);

                _this.parent().find(".tab").removeClass("active").addClass("inactive");
                _this.removeClass("inactive").addClass("active");

                $("#editores .divs").fadeOut(200);
                setTimeout(function () { $(".show_" + _this.attr("id")).fadeIn(); }, 200);

                if (_this.hasClass("tab_localizacao") && !mapa.existe) {
                    setTimeout(function () {
                        mapa.load();
                    }, 300);
                }

               

                

                if (_this.hasClass("tab_multimedia") && !uploader.existe) {
                    setTimeout(function () {
                        uploader.load();
                    }, 300);
                    $("#submenu .submenuitem:first-child").addClass("active");
                }

                if (_this.hasClass("tab_multimedia")) {
                    $("#submenu").animate({ height: 25 }, 200);
                }
                else {
                    $("#submenu").animate({ height: 0 }, 200);
                }


                if (_this.hasClass("tab_textos"))
                    $("#submenuTextos").animate({ height: 25 }, 200);
                else
                    $("#submenuTextos").animate({ height: 0 }, 200);
            })


            /* -- Submenu Multimedia -- */
            $("#submenu .submenuitem").click(function () {
                var _this = $(this);
                $("#submenu .submenuitem").removeClass("active");
                _this.addClass("active");
                $("#uploader_imagens,#uploader_documentos,#uploader_videos_holder").hide();

                var id = _this.attr("id").replace("open_", "");
                $("#" + id).fadeIn();
                console.log(id);
            })

            /* -- Submenu idiomas -- */
            $("#submenuTextos .submenuitem").click(function () {
                var _this = $(this);
                $("#submenuTextos .submenuitem").removeClass("active");
                _this.addClass("active");

                $("#pt,#en").hide()
                var id = _this.attr("id").replace("open_lingua_", "");
                $("#" + id).fadeIn();
            })

        },
        bindDatePickers:function(){
            /* -- DATA INPUTS -- */
            if ($(".data_input").length > 0) {
                $(".data_input:not(.hasDatepicker)").datepicker({
                    changeMonth: true,
                    changeYear: true,
                    yearRange: "1900:" + (new Date().getFullYear()+5),
                    dateFormat: "dd-mm-yy",
                    monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
                    dayNamesMin: ["Do", "Se", "Te", "Qu", "Qu", "Se", "Sa"],
                });
            }
        },
        bindAdminClicks: function () {
            var _this = this;

            /* -- Save -- */
            $("#div_edicao").on("click", ".btn_gravar", function () {

                if (Page_ClientValidate("CreateUserWizard1")) {
                    editores.encode();
                    __doPostBack($(".btn_gravar_ghost").attr("name"), '');

                } else {
                    erro("Preencha os campos obrigatórios");
                }
            })

            /* -- Eliminar -- */
            $("#div_edicao").on("click", ".btn_eliminar_artigo", function () {

                notify("confirm", "Pretende eliminar este artigo?", function () {
                    editores.encode();
                    __doPostBack($(".btn_eliminar_artigo_ghost").attr("name"), '');
                })

            })


            _this.bindDatePickers();


            /* -- Datas de Validade -- */
            if ($(".validade_checks_trigger input").is(":checked")) {
                $(".validade_checks").show();
            }

            $(".validade_checks_trigger input").change(function () {
                if ($(this).is(":checked"))
                    $(".validade_checks").slideDown();
                else
                    $(".validade_checks").slideUp();
            })


            /* -- Seo Tags -- */
            $(".show_tab_meta").on("click", ".btn_adicionartag_submit", function () {

                if (Page_ClientValidate("CreateUserWizard3")) {
                    editores.encode();
                    __doPostBack($(".btn_adicionartag_submit_ghost").attr("name"), '');
                }
            })

            $(".show_tab_meta").on("click", ".btn_remover_tag", function () {

                var id = $(this).parent().find("input:first").val();
                $("#hfIdTag").val(id);
                editores.encode();
                __doPostBack($(".eliminar_ghost").attr("name"), '');
            })


            /* -- Artihos relacionados -- */
            $(".show_tab_relacoes").on("change", ".rel_check input", function () {

                var id1 = $("#idholder input").val(),
                    id2 = $(this).closest(".rel_item").find(".hf_holder input").val(),
                    accao = $(this).is(":checked") ? "guarda" : "elimina";

                artigos.defineRelaco(accao, id1, id2, "10", "7");
            })
        }
    },

    defineProp: function (prop, ids, valor, obj) {
        'use strict'

        $.ajax({
            type: "GET",
            data: { a: prop, id: ids, v: valor, idsite: idSiteGlobal },
            url: '/api/artigos/setProp.ashx',
            dataType: "html",
            success: function (xml) {

                obj.attr("src", "/assets/release/images/" + prop + "_" + (valor == 1 ? "on" : "off") + ".png");
                obj.fadeIn();
                obj.removeClass("loading");

            }, error: function (XMLHttpRequest, textStatus, errorThrown) {
            }
        })
    },
    defineOrdem: function (ordem, pagesize, actual) {
        $(".ordem").animate({ "text-indent": -20 }, 100);
        $.ajax({
            type: "GET",
            data: { o: ordem, p: pagesize, a: actual, idsite: idSiteGlobal },
            url: '/api/artigos/setProp.ashx',
            dataType: "html",
            error: function (XMLHttpRequest, textStatus, errorThrown) {
            },
            success: function (xml) {
                var startCount = pagesize * (actual - 1);
                $(".ordem").each(function () {
                    var ordem = $(this).parent().index() + 1 + startCount
                    if (ordem < 10)
                        ordem = "0" + ordem;
                    $(this).text(ordem);
                })
                $(".ordem").animate({ "text-indent": 0 }, 200);
            }
        })
    },
    defineRelaco: function (accao, id1, id2, idfam1, idfam2) {
        $.ajax({
            type: "GET",
            data: { a: accao, idsrel: id1 + "-" + id2, idfams: idfam1 + "-" + idfam2 },
            url: '/api/artigos/setProp.ashx',
            dataType: "html",
            error: function (XMLHttpRequest, textStatus, errorThrown) { },
            success: function (xml) { }
        })
    }
}

var listas = {
    init: function (url, pagesize, apiData) {
        'use strict'

        if (!url) {
            url = '/api/getContactos.ashx';
        }

        //// PERDE ALGURES O VALOR?
        idSiteGlobal = $("#hf_idsite").val();
        

        var _this = this,
            tabelaHolderObj = $('#tabelaHolder'),
            opcoesTabela = {
                pageSize: pagesize ? pagesize: artigos.listagem.pageSize,
                apiUrl: url,
                apiData: apiData ? apiData : { pg: '1', tipo: $(".ddl_tipos").val(), idsite: idSiteGlobal },
                callback: _this.onTableCreated
            }

        //console.log("opcoes", opcoesTabela);

        tabela.init(tabelaHolderObj, opcoesTabela);
     
        //////////////////////////NEWSLETTER77777777777777777777777777777777777777
        //// USER CONSENTE OU NAO
        $(".listagem.newsletter").on("click", ".js_consente_cliente", function () {

            var _this = this;
            var consent = $(_this).data("consent");
            var id = $(_this).data("id");

            var data = {
                consent: consent,
                id: id,
            };

            $.ajax({
                type: "GET",
                data: data,
                url: '/api/newsletter/consentimentoInscritos.ashx',
                dataType: "html",
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    erro("Não foi possível gravar as alterações<br/>" + textStatus);
                },
                success: function (xml) {

                    //console.log("xml ", xml);

                    /// mudar ícone
                    if (xml == "1") {
                        $(_this).attr('src', '/assets/release/images/consent_on.png');
                        $(_this).data('consent', 1);
                    } else {
                        $(_this).attr('src', '/assets/release/images/consent_off.png');
                        $(_this).data('consent', 0);
                    }

                    sucesso("Registo alterado com sucesso");
                }
            })
        })
        $(".listagem.newsletter").on("click", ".js_del_news", function () {

            var _this = this;
            var id = $(_this).data("id");

            var data = {
                id: id,
            };

            var mensagem = "<span style='font-size:16px; display:block; padding-top:25px; padding-bottom:25px;'>Tem a certeza que pretende eliminar este registo? Esta operação é irreversível</span>";
            var titulo = "Alerta";

            //notify("confirm", mensagem, listas.confirmaDelte(data), titulo);


            notify("confirm", mensagem, function () {

                $.ajax({
                    type: "GET",
                    data: data,
                    url: '/api/newsletter/eliminarInscritos.ashx',
                    dataType: "html",
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        erro("Não foi possível gravar as alterações<br/>" + textStatus);
                    },
                    success: function (xml) {

                        var href = $(".button_refresh").attr('href');
                        window.location.href = href;
                        //$(".button_refresh").trigger('click');
                    }
                })

            }, titulo)
        });
        //////// novos cliques newsletter
        /// desactivar
        $(".listagem.newsletter").on("click", ".js-click", function () {

            var _this = $(this),
                prop = ""
            //id = parseInt(_this.closest("tr").find(".tbcol1").text(), 10),
            //valor = _this.attr("src").indexOf("off") > 0 ? 1 : 0;

            if (_this.hasClass("js-toggleActivo")) {
                prop = 0;
            }
            else if (_this.hasClass("js-togglePublico")) {
                prop = 1;
            }


            var activo = _this.data("activo");
            var id = _this.data("id");


            console.log("activo, ", activo);

            _this.addClass("loading");
            _this.fadeOut(200, function () {
                listas.definePropNews(activo, id, _this);
            });
        });

        /////////////////////////////////////////////////////////////////////

        //////////////////CONTACTOS7777777777777777777777777777777777
        //// USER CONSENTE OU NAO
        $(".listagem.contactos").on("click", ".js_consente_cliente", function () {
            event.stopPropagation();
            var _this = this;
            var consent = $(_this).data("consent");
            var id = $(_this).data("id");

            var data = {
                consent: consent,
                id: id,
            };

            $.ajax({
                type: "GET",
                data: data,
                url: '/api/contactos/consentimentoInscritos.ashx',
                dataType: "html",
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    erro("Não foi possível gravar as alterações<br/>" + textStatus);
                },
                success: function (xml) {

                    console.log("xml ", xml);

                    /// mudar ícone
                    if (xml == "1") {
                        $(_this).attr('src', '/assets/release/images/consent_on.png');
                        $(_this).data('consent', 1);
                    } else {
                        $(_this).attr('src', '/assets/release/images/consent_off.png');
                        $(_this).data('consent', 0);
                    }

                    sucesso("Registo alterado com sucesso");
                }
            })
        })
        ///// ELIMINAR USER
        $(".listagem.contactos").on("click", ".js_del_contacto", function () {

            var _this = this;
            var id = $(_this).data("id");

            var data = {
                id: id,
            };

            var mensagem = "<span style='font-size:16px; display:block; padding-top:25px; padding-bottom:25px;'>Tem a certeza que pretende eliminar este registo? Esta operação é irreversível</span>";
            var titulo = "Alerta";

            //notify("confirm", mensagem, listas.confirmaDelte(data), titulo);


            notify("confirm", mensagem, function () {

                $.ajax({
                    type: "GET",
                    data: data,
                    url: '/api/contactos/eliminarInscritos.ashx',
                    dataType: "html",
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        erro("Não foi possível gravar as alterações<br/>" + textStatus);
                    },
                    success: function (xml) {

                        var href = $(".button_refresh").attr('href');
                        window.location.href = href;
                        //$(".button_refresh").trigger('click');
                    }
                })

            }, titulo)
        });

        /////////////////////////////////////////////////////////////////////////////////



        $(".ddl_tipos").change(function () {
            var tabela = $('#tabelaHolder').data("WATable");
            tabela.option("urlData", { pg: '1', tipo: $(this).val(),idsite:idSiteGlobal })
            tabela.update();
        })

        $(".input_nome").change(function () {
            var tabela = $('#tabelaHolder').data("WATable");
            tabela.option("urlData", { pg: '1', tipo: $(".ddl_tipos").val(), idsite: idSiteGlobal, texto: $(this).val() })
            tabela.update();
        })

        $(".lbl_insert").click(function () {

            var titulo = $(this).data("titulo"),
                placeholder = $(this).data("placeholder"),
                url = $(this).data("url"),
                urlSearch = $(this).data("urlsearch");

            var htmlPop = "<div id='confirm'> <div class='header'><span>" + titulo + "</span></div>" +
                "<div class='message'><b>" + placeholder + "</b><input id='input_new_item' type='text' /></div>" +
                "<div class='buttons'><div class='yes'>Inserir</div></div></div>";

            $.modal(htmlPop, {
                closeHTML: "<a href='#' title='Close' class='modal-close'>x</a>",
                position: ["20%", ],
                overlayId: 'confirm-overlay',
                containerId: 'confirm-container',
                onShow: function (dialog) {
                    var modal = this;


                    if (urlSearch) {

                        $("#input_new_item").select2({
                            minimumInputLength: 3,
                            query: function (query) {


                                $.ajax({
                                    type: "GET",
                                    data: {  texto: query.term , idsite: idSiteGlobal},
                                    url: urlSearch,
                                    dataType: "json",
                                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                                        erro("Erro a obter dados<br/>" + textStatus);
                                    },
                                    success: function (resultados) {
                                        var data = { results: [] };

                                        if (resultados.rows) {
                                            resultados = resultados.rows;
                                        }
                                        if (resultados) {

                                            for (var i = 0; i < resultados.length; i++) {
                                                data.results.push({ id: resultados[i].id, text: $(resultados[i].nome).text() + " (" + resultados[i].email + ")" });
                                            }

                                        }
                                        query.callback(data);
                                    }
                                })
                            }
                        });

                    }

                    


                    // if the user clicks "yes"
                    $('.yes', dialog.data[0]).click(function () {
                        if ($("#input_new_item").val().length > 0) {

                            var valores = $(".lbl_insert").data("valores");

                            if (!valores) {
                                valores = {};
                            } 
                            valores.input = $("#input_new_item").val();

                            if (urlSearch) {

                                valores.input = $("#input_new_item").select2('data').id;
                            }


                            listas.insertNewItem(url, valores);
                        }
                        
                        // close the dialog
                        modal.close(); // or $.modal.close();
                    });
                }
            });

        })


        



    },
    definePropNews: function (activo, id, obj) {

        'use strict'

        var prop;

        if (activo == 1) {
            prop = 0;
        } else {
            prop = 1;
        }


        $.ajax({
            type: "GET",
            data: { a: prop, id: id },
            url: '/api/newsletter/activaInscritos.ashx',
            dataType: "html",
            success: function (xml) {

                console.log("xml, ", xml);

                obj.attr("src", "/assets/release/images/activo_" + (xml == "1" ? "on" : "off") + ".png");
                obj.fadeIn();
                obj.removeClass("loading");
                var novoValorData;
                if (xml == "1") {
                    novoValorData = 1;
                } else {
                    novoValorData = 0;
                }
                obj.data('activo', novoValorData);

            }, error: function (XMLHttpRequest, textStatus, errorThrown) {

            }
        })

    },
    onTableCreated: function () {
        'use strict'

        $("#tabelaHolder").on("click", ".watable tr", function (e) {

            if (!$(e.target).hasClass("js-click") && $(e.target).closest(".no-click").length==0) {
                e.preventDefault();

                var href = $(this).find("a").attr("href");
                if (href) {
                    window.location = href;
                }
            }

            

        })

    },
    insertNewItem:function(url,valores){
    
        valores.idsite = idSiteGlobal;

        $.ajax({
            type: "GET",
            data: valores,
            url: url,
            dataType: "html",
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                erro("Não foi possível inserir o novo item<br/>" + textStatus);
            },
            success: function (xml) {
                if (xml == "true") {
                    sucesso("Item Inserido com sucesso");
                    //window.location.reload();

                    $('#tabelaHolder').data("WATable").update();

                } else {
                    erro("Não foi possível inserir o novo item<br/>" + xml);
                }
            }
        })
    },

    detalhe: {
        init: function () {

            $("#editores .divs:first-child").fadeIn();
            $(".alteracoes_recentes_titulo").click(function () {
                $(".alteracoes_recentes ul").slideToggle();
            })

            if (podeEditar) {
                if (typeof tinyMCE != 'undefined') {
                    editores.init();
                }

                $("#div_edicao").on("click", ".btn_gravar", function () {
                    editores.encode();
                    __doPostBack($(".btn_gravar_ghost").attr("name"), '');
                })

                /* -- Eliminar -- */
                $("#div_edicao").on("click", ".btn_eliminar_artigo", function () {

                    notify("confirm", "Pretende eliminar este contacto?", function () {
                        editores.encode();
                        __doPostBack($(".btn_eliminar_artigo_ghost").attr("name"), '');
                    })

                })
            }

        }
    }
}


var variaveis = {
    init: function (url) {

        var _this = this,
        tabelaHolderObj = $('#tabelaHolder'),
        opcoesTabela = {
            pageSize: 30,
            apiUrl: url,
            apiData: { pg: '1', tipo: '', s: '' },
            callback: _this.onTableCreated
        }
        tabela.init(tabelaHolderObj, opcoesTabela);

        _this.bindFiltrosClicks();

        if (podeEditar) {
            _this.bindAdminClicks();
        }

    },
    bindFiltrosClicks: function () {
        $(".ddl_tipos").change(function () {
            var pesquisaVal = $(".tbx_pesquisa").val().trim(),
                tipoVal = $(this).val(),
                tabela = $('#tabelaHolder').data("WATable");

            tabela.option("urlData", { pg: 1, tipo: tipoVal, s: pesquisaVal })
            tabela.update();

            $(".tbx_pesquisa").data("oldSearch", pesquisaVal);
        })

        $(".lbl_pesquisar").click(function () {
            var pesquisaVal = $(".tbx_pesquisa").val().trim(),
                tipoVal = $(".ddl_tipos").val(),
                tabela = $('#tabelaHolder').data("WATable");

            if ($(".tbx_pesquisa").data("oldSearch") !== pesquisaVal) {
                tabela.option("urlData", { pg: 1, tipo: tipoVal, s: pesquisaVal })
                tabela.update();

                $(".tbx_pesquisa").data("oldSearch", pesquisaVal);
            }
        })
    },
    bindAdminClicks: function () {

        $(".listagem.textosvariaveis").on("click", ".btn_gravar_list", function (e) {
            e.preventDefault();
            var _holderObj = $(this).closest("tr"),
                xid = _holderObj.find(".id_linha input").val(),
                variaveis = { id: xid };

            _holderObj.find("textarea").each(function () {
                variaveis[$(this).attr("data-var")] = $(this).val();
            })

            $.ajax({
                type: "GET",
                data: variaveis,
                url: '/api/config/setTextoVariavel.ashx',
                dataType: "html",
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    erro("Não foi possível gravar as alterações<br/>" + textStatus);
                },
                success: function (xml) {
                    if (xml == "true") {
                        sucesso("Texto guardado com suscesso");
                    } else {
                        erro("Não foi possível gravar as alterações<br/>" + xml);
                    }
                }
            })
        })
    }


}

var mapa = {
    existe: false,
    load: function () {

        if (!mapa.existe) {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp&callback=mapa.init&key=AIzaSyCqkGWT-3TeNmfHNoc50HghR0udkzqeBCk';
            document.body.appendChild(script);
        }


    },
    init: function () {

        $.getScript("/assets/release/js/standalone/jquery.ma.mapa.js", function (data, textStatus, jqxhr) {

            if (!parseInt(zoom)) {
                zoom = "8";
            }

            if (marcadores.length > 0) {
                $("#mapa").mapaMA({
                    mapOptions: { zoom: zoom },
                    dragEnd: function (lat, lng, zoom, id) {
                        mapa.updateCoordenadas(lat, lng, zoom, id);
                        $(".coordenas_holder").val(lat + "," + lng)
                    },
                    marcadores: marcadores
                });
            }
            mapa.existe = true;
        });

    },
    updateCoordenadas: function (latitude, longitude, zoom, idArtigo) {
        /*$.ajax({
            type: "GET",
            data: { lat: latitude, lng: longitude, z: zoom, id: idArtigo },
            url: '/api/artigos/setProp.ashx',
            dataType: "html",
            error: function (XMLHttpRequest, textStatus, errorThrown) { },
            success: function (xml) { }
        })*/
    },
    updatePosition: function (latitude, longitude) {
        $("#mapa").mapaMA("refresh", [latitude,longitude]);
        
    }
}


var media = {
    updateLista: function (tipo) {

        editores.encode();

        if (tipo == "img") {
            __doPostBack($(".button_update_ghost").attr("name"), '');
        }
        else if (tipo=="video") {
            __doPostBack($(".button_update_ghostvideos").attr("name"), '');
        }
        else {
            __doPostBack($(".button_update_ghostdocs").attr("name"), '');
        }

        uploader.ajustaTamanho(100, tipo);
    },

    loadSortable: function () {


        $(".painel_fotos .media_item,.painel_docs .doc_item").each(function () { $(this).attr("id", "m_" + $(this).find(".hf_holder input").val()); })

        $(".painel_fotos").sortable({
            forceHelperSize: true, containment: "parent", forcePlaceholderSize: true, handle: ".pic",
            stop: function (event, ui) {
                var order = $(".painel_fotos").sortable('serialize').replace(/&/g, "-");
                media.defineOrdem(order);
            }
        });
    },
    bindClicks: function () {


        /* EDITAR DESCRICAO IMAGEM */
        $("#editores").on("blur", ".media_input", function () {

            var _this = $(this),
                idioma = _this.data("idioma"),
                xid = "",
                valor = _this.val(),
                tipo = _this.closest(".painel_fotos").length ? 'img' : 'doc',
                funcao = function () {
                    sucesso("Descrição guardada com sucesso");
                };


                if(_this.closest(".doc_item").length){
                    xid=_this.closest(".doc_item").attr("id").replace("m_", "")
                }else{
                    xid=_this.parent().attr("id").replace("m_", "")
                }

            media.defineProp("descr", xid, valor, funcao, tipo, idioma)
        });

        /* EDITAR TIPO DOCUMENTO */
        $("#editores").on("blur", ".media_tipodocs", function () {

            var _this = $(this),
               xid = _this.parent().attr("id").replace("m_", ""),
               valor = _this.val(),
               tipo = _this.closest(".painel_fotos").length ? 'img' : 'doc',
               funcao = function () { };

            media.defineProp("tipodocs", xid, valor, funcao, tipo);

        });

        /* -- Checks das imagens --  */
        $("#editores").on("click", ".media_check input", function () {

            var _this = $(this),
                valor = _this.is(":checked") ? "1" : "0",
                inputName = _this.attr("name"),
                campo = inputName.substring(inputName.lastIndexOf('$') + 1, inputName.length).replace("chk_", ""),
                tipo="img";

            if (_this.closest(".painel_fotos").length) {
                tipo="img";
            } else if (_this.closest(".painel_videos").length) {
                tipo="video";
            }else{
                tipo="doc";
            }

            if ( tipo=="img") {
                var xid = _this.closest(".media_item").attr("id").replace("m_", ""),
                    funcao = function () { };

                media.defineProp(campo, xid, valor, funcao, tipo);

            } else{
                var xid = _this.closest(".doc_item").find(".hf_holder input").val(),
                    funcao = function () { };

                media.defineProp(campo, xid, valor, funcao, tipo);
            }
        })

        /* -- Apagar item media --  */
        $("#editores").on("click", ".btn_remove_media", function () {
            var _this = $(this),
                id = _this.parent().find(".hf_holder input").val();


            if (!_this.hasClass("aeliminar")) {
                _this.addClass("aeliminar")

                maTimer.init(_this, id, function () {

                    var tipo = "doc";
                    if (_this.closest(".painel_fotos").length) {
                        tipo = "img";
                    } else if (_this.closest(".painel_videos").length) {
                        tipo = "video";
                    }

                    var funcao = function () {
                        _this.parent().remove();
                        //sucesso("Imagem removida com sucesso");
                    }

                    media.defineProp("eliminar", id, "1", funcao, tipo);
                });

            } else {
                $(this).removeClass("aeliminar").text("Eliminar");
            }

        })


    },
    defineProp: function (accao, xid, valor, funcao, tipo, idioma) {

        $.ajax({
            type: "GET",
            data: { t: tipo, id: xid, a: accao, v: valor,idioma:idioma },
            url: '/api/media/setProp.ashx',
            dataType: "html",
            error: function (XMLHttpRequest, textStatus, errorThrown) { },
            success: function (xml) {

                if (xml == "false") {
                    sucesso("Oops. Não foi possível concluir a acção");
                } else {
                    funcao();
                }
            }
        })

    },
    defineOrdem: function (ordem) {
        $(".painel_fotos").css("opacity", ".5");
        $.ajax({
            type: "GET",
            url: '/api/artigos/actionsMedia.aspx?o=' + ordem,
            dataType: "html",
            error: function (XMLHttpRequest, textStatus, errorThrown) {
            },
            success: function (xml) {
                $(".painel_fotos").animate({ "opacity": 1 }, 200);
            }
        })
    }
}

var uploader = {
    existe: false,
    load: function () {

        var id = $("#uploaderId input").val();
        $(".uploader_fotos").append("<iframe id='uploader_iframe' style='width:100%;border:0;overflow:hidden;' src='/editar/uploader/upload.aspx?id=" + id + "&tipo=img&idsite="+idSiteGlobal+"' />");
        $("#uploader_iframe").load(function () {
            $(".uploader_fotos").addClass("loaded").animate({ height: 100 }, 200);
            uploader.existe = true;
        })

        $(".uploader_docs").append("<iframe id='uploader_iframe_docs' style='width:100%;border:0;overflow:hidden;' src='/editar/uploader/upload.aspx?id=" + id + "&tipo=doc&idsite=" + idSiteGlobal + "' />");
        $("#uploader_iframe_docs").load(function () {
            $(".uploader_docs").addClass("loaded").animate({ height: 100 }, 200);
            uploader.existe = true;
        })

        $(".uploader_videos").append("<iframe id='uploader_iframe_videos' style='width:100%;border:0;overflow:hidden;' src='/editar/uploader/upload.aspx?id=" + id + "&tipo=video&idsite=" + idSiteGlobal + "' />");
        $("#uploader_iframe_videos").load(function () {
            $(".uploader_videos").addClass("loaded").animate({ height: 100 }, 200);
            uploader.existe = true;
        })

    },

    ajustaTamanho: function (altura, tipo) {

        if (tipo == "img") {
            $(".uploader_fotos").stop().animate({ height: altura }, 200);
        } else if (tipo == "video") {
            $(".uploader_videos").stop().animate({ height: altura }, 200);
        }
        else {
            $(".uploader_docs").stop().animate({ height: altura }, 200);

            if ($(".painel_docs .doc_item").length) {
                $("#ContentPlaceHolder1_detalhes_documentos").show();
            } else {
                $("#ContentPlaceHolder1_detalhes_documentos").hide();
            }
        }

    }
}


var horarios = {
    existe: false,
    init: function () {
        var _this = this;

        _this.startCalendarios();


        /* -- Tabela Dias Actuais -- */
        $(".horarios_table").html("");
        var tabelaHolderObj = $('.horarios_table'),
            opcoesTabela = {
                pageSize: 20,
                apiUrl: '/api/artigos/getDatasCurso.ashx',
                apiData: { pg: '1', id: $("#idholder input").val() },
                callback: function () {
                    'use strict'
                }
            }

        tabela.init(tabelaHolderObj, opcoesTabela);

        if (podeEditar && !_this.existe) {
            _this.bindAdminClicks();
        }

        _this.existe = true;

    },
    startCalendarios: function () {
        var _this = this;


        artigos.detalhe.bindDatePickers();

        $(".data_start").datepicker("option", {
            minDate: 0,
            maxDate: '+1y',
            firstDay: 1,
            onSelect: function (data, obj) {

                var dateParts = data.split('-'),
                    dataObj = new Date(dateParts[2], dateParts[1], dateParts[0]),
                    diaSemana = dataObj.getDay();

                $(".ddl_dias_semana").val(diaSemana);

                $("#hf_dias_inserir").val(dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0]);
            },
            beforeShowDay: function (data) {

                var result = _this.verificaExcepcoes(data, feriados);
                if (result === -1) {
                    return [true];
                } else {
                    return [false, '', feriados[result].nome];
                }
            }
        });

        $(".ddl_dias_semana").val($(".data_start").datepicker("getDate").getDay());


        $(".data_end").datepicker("option", {
            minDate: 0,
            maxDate: '+1y',
            firstDay: 1,
            onSelect: function (data, obj) {

                var dataInicio = $(".data_start").datepicker("getDate"),
                    dataFim = $(".data_end").datepicker("getDate"),

                    /* -- Nr de vezes que aparece o dia da semana escolhido, entre as datas selecionadas --*/
                    nrDiasSemana = _this.countCertainDays([$(".ddl_dias_semana").val()], dataInicio, dataFim),

                    textoDias = "<b>Vão ser criadas as seguintes datas:</b><br/>",
                    dataTemp = new Date(dataInicio.getTime()),
                    dataS = "",
                    nrDiasValidos = 0,
                    diasValidos = [];

                for (var i = 0; i < nrDiasSemana; i++) {

                    dataS = dataTemp.getFullYear() + "-" + (dataTemp.getMonth() + 1) + "-" + dataTemp.getDate();

                    var existeFeriado = _this.verificaExcepcoes(dataTemp, feriados);
                    if (existeFeriado === -1) {
                        textoDias += (i + 1) + "ª Data: <b>" + dataS + "</b><br/>";

                        diasValidos.push(dataS);
                        nrDiasValidos++;
                    } else {
                        textoDias += "<strike>" + (i + 1) + "ª Data: <b>" + dataS + "</b></strike> (" + feriados[existeFeriado].nome + ") <br/>";
                    }

                    dataTemp.setDate(dataTemp.getDate() + 7);
                }

                $(".lbl_status_recorrente").html(textoDias);

                if (nrDiasValidos > 0) {

                    $("#hf_dias_inserir").val(diasValidos.join());

                    if (nrDiasValidos == 1) {
                        $(".lbl_add_day").text("Inserir data");
                    } else {
                        $(".lbl_add_day").text("Inserir as " + nrDiasValidos + " datas");
                    }
                }
            },
            beforeShowDay: function (data) {
                var result = _this.verificaExcepcoes(data, feriados);
                if (result === -1) {
                    return [true];
                } else {
                    return [false, '', feriados[result].nome];
                }
            }
        });

    },
    bindAdminClicks:function(){
        var _this = this;

        /* -- Slide Recorrente -- */
        $("#editores").on("change",".chk_recorrente input",function() {
            if ($(this).is(":checked")) {
                $(".recorrente").addClass("show");
            } else {
                $(".recorrente").removeClass("show");
            }
        })

        /* -- Insert Dias -- */
        $("#editores").on("click", ".lbl_add_day", function () {

            if ($("#hf_dias_inserir").val().length > 0) {
                editores.encode();
                __doPostBack($(".lbl_add_day_ghost").attr("name"), '');

            } else {
                erro("Selecione uma data");
            }
        })

        /* -- Activo / Inactivo -- */
        $("#editores").on("click", ".js-activo", function (e) {
            e.preventDefault();

            var _this = $(this),
                _holderObj = _this.closest("tr"),
                xid = parseInt(_holderObj.find(".tbcol1").text(), 10),
                valor = _this.attr("src").indexOf("off") > 0 ? 1 : 0,
                variaveis = { a: "activo", id: xid, v: valor };

            _this.fadeOut();

            $.ajax({
                type: "GET",
                data: variaveis,
                url: '/api/artigos/setDataCursoProp.ashx',
                dataType: "html",
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    erro("Não foi possível gravar as alterações<br/>" + textStatus);
                },
                success: function (xml) {
                    if (xml == "false") {
                        erro("Não foi possível gravar as alterações<br/>" + xml);
                    } else {

                        _this.attr("src", "/assets/release/images/activo_" + (valor == 1 ? "on" : "off") + ".png");
                        _this.fadeIn();

                        sucesso("Estado guardado com suscesso");

                    }
                }
            })
        })
    
    },
    verificaExcepcoes: function (data, arrayExcepcoes) {

        var anoActual = new Date().getFullYear(),
            item = {},
            dateParts = [],
            ano = "",
            output = -1;

        for (var i = 0; i < arrayExcepcoes.length; i++) {
            item = arrayExcepcoes[i];

            dateParts = item.data.split('-');

            ano = dateParts[0];

            if (item.fixo) {
                ano = anoActual;
            }

            var dataLocal = new Date(ano, dateParts[1], dateParts[2]);


            if (data.getTime() === dataLocal.getTime()) {
                output = i;
                i = arrayExcepcoes.length;
            }
        }

        return output;
    },
    countCertainDays: function (days, d0, d1) {
        var ndays = 1 + Math.round((d1 - d0) / (24 * 3600 * 1000));
        var sum = function (a, b) {
            return a + Math.floor((ndays + (d0.getDay() + 6 - b) % 7) / 7);
        };
        return days.reduce(sum, 0);
    }
}


function sucesso(texto) {
    notify("alert", texto);
}
function erro(texto) {
    notify("alert", texto);
}

function notify(type, mensagem, callback) {


    var htmlPop = "<div id='confirm'> <div class='header'><span>Atenção</span></div><div class='message'></div>" +
    "<div class='buttons'><div class='yes'>Ok</div></div></div>";


    if (type == "confirm") {
        htmlPop = "<div id='confirm'> <div class='header'><span>Confirmar</span></div><div class='message'></div>" +
    "<div class='buttons'><div class='no simplemodal-close'>Não</div><div class='yes'>Sim</div></div></div>";

    }


    $.modal(htmlPop, {
        closeHTML: "<a href='#' title='Close' class='modal-close'>x</a>",
        position: ["20%", ],
        overlayId: 'confirm-overlay',
        containerId: 'confirm-container',
        onShow: function (dialog) {
            var modal = this;

            $('.message', dialog.data[0]).append(mensagem);

            // if the user clicks "yes"
            $('.yes', dialog.data[0]).click(function () {
                // call the callback
                if ($.isFunction(callback)) {
                    callback.apply();
                }
                // close the dialog
                modal.close(); // or $.modal.close();
            });
        }
    });

}